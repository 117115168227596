'use client';

// import { cn } from '@@libs/utils';
import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';

/**
 * Types
 */
type ToasterProps = React.ComponentProps<typeof Sonner>;

/**
 * Constants
 */
const ToastOptions: ToasterProps['toastOptions'] = {
  duration: 5000,
  classNames: {
    toast: 'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
    description: 'group-[.toast]:text-muted-foreground',
    actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
    cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
  },
};
const ToasterConfig: ToasterProps = {
  // cn,
  richColors: true,
  closeButton: true,
  className: 'toaster group',
  toastOptions: ToastOptions,
};

/**
 * Components: Toaster
 */
const Toaster = (props: ToasterProps) => {
  const { theme = 'system' } = useTheme();
  return <Sonner theme={theme as ToasterProps['theme']} {...ToasterConfig} {...props} />;
};

export { Toaster };
