'use client';

import { SessionProvider, SessionProviderProps } from 'next-auth/react';

import { NextAuthProviderConfig } from '@@configs/server/next-auth.config';

/**
 * Provider: NextAuthProvider
 */
export default function NextAuthProvider({ children, session, ...props }: SessionProviderProps) {
  return (
    <SessionProvider session={session} {...NextAuthProviderConfig} {...props}>
      {children}
    </SessionProvider>
  );
}
