'use client';

import Link from 'next/link';
import { LogIn, LogOut, User, UserPlus, UserX } from 'lucide-react';
import { signOut, useSession } from 'next-auth/react';

import { Avatar, AvatarFallback } from '@@components/ui/avatar';
import Button from '@@components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@@components/ui/dropdown-menu';
import ThemeSwitcher from '@@components/layout/nav/ThemeSwitcher';

// import { auth } from '@@libs/next-auth';

/**
 * Types
 */
type NavUserMenuProps = {
  //n
};

/**
 * Constants
 */
//TODO: create a path or try api/auth/session
// const getSession = unstable_cache(
//   () =>
//     fetch(
//       new URL('/api/auth/session', url), //
//       { next: { revalidate: 10, tags: ['auth-session'] } }
//     )
//       .then((res) => res.json())
//       .then((data) => data.user) as unknown as Promise<Session>,
//   ['auth-session'],
//   {
//     revalidate: 10,
//     tags: ['auth-session'],
//   }
// );

/**
 * Component: NavUserMenu
 */
export default function NavUserMenu(_props: NavUserMenuProps) {
  // const session = await auth();
  // const session = await getSession();
  const { data: session, status } = useSession();
  const monogram = session?.user?.name.split(' ').map(([c]) => c).join(''); //prettier-ignore
  // console.log('NavUserMenu', session, monogram);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost" //
          className="relative ml-auto size-10 rounded-full"
          disabled={status === 'loading'}
        >
          <Avatar className="size-10">
            {/* <AvatarImage src={session?.user.image} alt="avatar" /> */}
            <AvatarFallback>{monogram ? monogram : <UserX />}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className="mt-2 w-56" //
        alignOffset={-8}
        align="end"
        forceMount
      >
        {/* {status === 'authenticated' ? ( */}
        {session ? (
          <DropdownMenuGroup>
            <DropdownMenuLabel className="space-y-1 leading-none">
              <p>{session?.user.name}</p>
              <p className="text-xs leading-none text-muted-foreground">{session?.user.email}</p>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild disabled>
              {/* @ts-expect-error */}
              <Link href="/profile">
                <User className="mr-2 size-4" />
                <span>Profile</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem className="!text-red-600 hover:!bg-red-600/10" onClick={() => signOut()}>
              <LogOut className="mr-2 size-4" />
              <span>Log out</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        ) : (
          <DropdownMenuGroup>
            <DropdownMenuItem asChild>
              <Link href="/login">
                <LogIn className="mr-2 size-4" />
                <span>Login</span>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <Link href="/signup">
                <UserPlus className="mr-2 size-4" />
                <span>Sign up</span>
              </Link>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        )}

        <DropdownMenuSeparator />
        <ThemeSwitcher />

        {/* <DropdownMenuItem disabled>
            <User className="mr-2 size-4" />
            <span>Profile</span>
            <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
          </DropdownMenuItem> */}
        {/* <DropdownMenuItem>
            <CreditCard className="mr-2 size-4" />
            <span>Billing</span>
            <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Settings className="mr-2 size-4" />
            <span>Settings</span>
            <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Keyboard className="mr-2 size-4" />
            <span>Keyboard shortcuts</span>
            <DropdownMenuShortcut>⌘K</DropdownMenuShortcut>
          </DropdownMenuItem> */}
        {/*
        <DropdownMenuGroup>
          <DropdownMenuItem>
            <Users className="mr-2 size-4" />
            <span>Team</span>
          </DropdownMenuItem>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <UserPlus className="mr-2 size-4" />
              <span>Invite users</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem>
                  <Mail className="mr-2 size-4" />
                  <span>Email</span>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <MessageSquare className="mr-2 size-4" />
                  <span>Message</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <PlusCircle className="mr-2 size-4" />
                  <span>More...</span>
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuItem>
            <Plus className="mr-2 size-4" />
            <span>New Team</span>
            <DropdownMenuShortcut>⌘+T</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator /> */}

        {/* <DropdownMenuItem>
          <LifeBuoy className="mr-2 size-4" />
          <span>Support</span>
        </DropdownMenuItem>
        <DropdownMenuItem disabled>
          <Cloud className="mr-2 size-4" />
          <span>API</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator /> */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
