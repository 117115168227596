'use client';

import { useState, type PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental';

import { ReactQueryOptions } from '@@configs/react-query.client';

/**
 * Types
 */
type ReactQueryProviderProps = PropsWithChildren<{
  // dehydratedState: unknown;
}>;

/**
 * Provider: Tanstack ReactQuery
 */
export default function ReactQueryProvider({
  // dehydratedState,
  children,
}: ReactQueryProviderProps) {
  const [queryClient] = useState(() => new QueryClient(ReactQueryOptions));
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryStreamedHydration>
        {children}
        {/* {process.env.NODE_ENV === 'development' && <ReactQueryDevtools buttonPosition="bottom-left" />} */}
      </ReactQueryStreamedHydration>
    </QueryClientProvider>
  );
}
