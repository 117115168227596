import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { PolymorphicComponentPropsWithRef } from '@@types/AsProp';
import { cn } from '@@libs/utils';

/**
 * Types
 */
// eslint-disable-next-line unused-imports/no-unused-vars
export interface _ButtonProps<T extends React.ElementType = typeof DefaultComponent>
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}
type ButtonProps<T extends React.ElementType = typeof DefaultComponent> = PolymorphicComponentPropsWithRef<T, _ButtonProps>;

/**
 * Constants
 */
const DefaultComponent = 'button';
const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

// <HTMLButtonElement, ButtonProps>
const Button = React.forwardRef(function Button<T extends React.ElementType = typeof DefaultComponent>(
  { className, variant, size, as, asChild = false, ...props }: ButtonProps<T>,
  ref: React.ForwardedRef<T>
) {
  const Comp = asChild ? Slot : DefaultComponent;
  const Component = as || Comp;

  return <Component className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
//@ts-expect-error
Button.displayName = 'Button';
export default Button;
export { Button, buttonVariants };
