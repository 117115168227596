import type { SessionProviderProps } from 'next-auth/react';

/**
 * Configs
 */
export const maxAge = 30 * 24 * 60 * 60; // 30 days
export const NextAuthProviderConfig = {
  refetchInterval: 30,
  refetchOnWindowFocus: true,
} satisfies Partial<SessionProviderProps>;
