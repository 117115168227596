'use client';

import { MouseEventHandler, useCallback } from 'react';
import { Monitor, Moon, Sun } from 'lucide-react';
import { useTheme } from 'next-themes';

import { DropdownMenuItem } from '@@components/ui/dropdown-menu';

/**
 * Types
 */
type ThemeSwitcherProps = {
  //n
};

/**
 * Component: ThemeSwitcher
 */
export default function ThemeSwitcher(_props: ThemeSwitcherProps) {
  const { theme, setTheme, themes } = useTheme();
  const onChangeTheme: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault();
      const current = themes.indexOf(theme as string);
      setTheme(themes[current == themes.length - 1 ? 0 : current + 1]);
    },
    [setTheme, theme, themes]
  );

  return (
    <DropdownMenuItem onClick={onChangeTheme}>
      {theme === 'system' && <Monitor className="mr-2 size-4" />}
      {theme === 'light' && <Sun className="mr-2 size-4" />}
      {theme === 'dark' && <Moon className="mr-2 size-4" />}
      <span>
        Theme: <b className="capitalize">{theme}</b>
      </span>
    </DropdownMenuItem>
  );
}
